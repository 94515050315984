import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { enqueueSnackbar } from 'notistack';
import Paper from '@material-ui/core/Paper';
import { DataGetApi } from '../../../helpers/PostDataApi';
import { config } from '../../../constants';
import LoadingOverlay from 'react-loading-overlay';
import { doesFileExist, formatCurrency, getMultipassData, getPaytraceCreds } from '../../../helpers/commonFunction';
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router';
import IntegrationDownshift from './../../../helpers/multiSelect';
import { locationList } from '../../../actions/LocationAction';
import FormControl from '@material-ui/core/FormControl';
import { Button, createMuiTheme, Grid, MuiThemeProvider, Tooltip } from '@material-ui/core';
import RangedDatePicker from '../../../components/DatePicker/RangedDatePicker';
import { ErrorMessage } from '../../../components/Message/ErrorMessage';
import { getBatchData, getLastUpdatedBatchData } from '../../../actions/BatchAction';
import { connect } from 'react-redux';
import { isNull } from 'lodash';
import SpinnerLoader from './SpinnerLoader';
import PropTypes from 'prop-types';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },

  container: {
    maxHeight: 440,
  },
  menuItem: {
    '&:hover': {
      backgroundColor: '#3b3f58',
    },
  },
  pagingWith: {
    width: '10%',
  },
  Underline: {
    color: 'blue',
    cursor: 'pointer',
    borderStyle: 'none',
    background: 'transparent',
    fontSize: '0.875rem',
  },
  buttonSearch: {
    position: 'relative',
    top: '41px',
    left: '17px',
    marginRight: '10px',
  },
  buttonClear: {},
  updatedRow: {
    marginLeft: '20px',
  },
  labelDate: {
    position: 'relative',
    top: 42,
  },
}));

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTablePagination: {
        toolbar: {
          width: '160px !important ',
        },
        root: {
          width: '497px !important',
        },
      },
      MuiTableCell: {
        root: {
          padding: '14px',
        },
      },
    },
  });

const getDatePickerThemes = () =>
  createMuiTheme({
    overrides: {
      MuiFormControl: {
        marginNormal: {
          marginLeft: '10px',
        },
      },
      MuiGrid: {
        container: {
          marginTop: '10px',
          marginBottom: 50,
        },
      },
    },
  });

function Batches(props) {
  const classes = useStyles();
  const [clickCounter, setClickCounter] = React.useState(1);
  const [batchData, setBatchData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState([]);
  const [toDate, setToDate] = React.useState(new Date());
  const [lastUpdatedDate, setLastUpdatedDate] = React.useState('N/A');
  const [isDisabledRefresh, setIsDisabledRefresh] = React.useState(false);
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const [loadingText, setLoadingText] = React.useState('Loading your content...');
  const [locationData, setLocationsData] = React.useState([]);
  const [locationSelectedwithmid, setLocationSelectedwithmid] = React.useState([
    JSON.parse(localStorage.getItem('locationPaydetails')),
  ]);
  const [company, setCompany] = React.useState({});
  const [marginBottom, setMarginBottom] = React.useState(10);
  const containerRef = React.useRef(null);

  let history = useHistory();
  const date = new Date();
  const todayDate = new Date(date.toLocaleDateString());
  const [fromDate, setFromDate] = React.useState(new Date(date.setDate(date.getDate() - 30)));

  const getUser = () => JSON.parse(localStorage.getItem('user'));
  const handleFromDateChange = (from) => {
    setFromDate(from);
  };

  const handleToDateChange = (to) => {
    setToDate(to);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container.scrollHeight > container.clientHeight) {
      setMarginBottom(container.scrollHeight);
    }

    /**
     * Historically project is not using querying & caching mechanism i.e. react-query, RTK query
     * hence wrapping async API calls in IIFE
     */
    (async function () {
      let apiOperations = [];
      if (getUser().role !== config.SUPER_ADMIN_ROLE) {
        apiOperations.push(getLocationData());
      }
      apiOperations.push(getBatchRecord());
      apiOperations.push(getLastUpdatedBatchDate());

      if (lastUpdatedDate === 'N/A') {
        apiOperations.push(handleRefreshBatchInfo());
      }
      await Promise.all(apiOperations);
    })();
  }, [locationSelectedwithmid]);

  useEffect(() => {
    /**
     * Historically project is not using querying & caching mechanism i.e. react-query, RTK query
     * hence wrapping async API calls in IIFE
     */
    (async function () {
      await getBatchRecord();
    })();
  }, [toDate, fromDate]);

  const handleRefreshBatchInfo = async () => {
    setLoading(true);
    setButtonLoader(true);
    setTimeout(() => {
      setClickCounter(1);
      setIsDisabledRefresh(false);
      setError([]);
    }, 60000);

    if (clickCounter >= 3) {
      setError([config.RATE_LIMITER_MESSAGES]);
      setIsDisabledRefresh(true);
    }
    setClickCounter(clickCounter + 1);

    if (lastUpdatedDate !== 'N/A') {
      setButtonLoader(true);
    }

    let user = getUser();
    let companyId = user.role !== config.SUPER_ADMIN_ROLE && user.company._id ? user.company._id : '';
    let token = user.token;

    let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    let isMultipass = locationPaydetails.gateway === 'multipass';
    const gatewayDetails = isMultipass ? getMultipassData() : getPaytraceCreds();

    if (!isMultipass) {
      const localUrl = config.BASEURL + 'getPemFile?fileName=';
      let file = doesFileExist(localUrl + gatewayDetails.paytraceKey);
      if (window.paytrace && file) {
        window.paytrace.setKeyAjax(localUrl + gatewayDetails.paytraceKey);
      } else {
        let msg = 'Paytrace file not exist. Kindly contact to super admin to re-upload the key.';
        setError([msg]);
        setTimeout(() => {
          setError([]);
        }, 3000);
      }
    }
    setLoadingText('Synchronising batch data...');
    setIsDisabledRefresh(true);

    try {
      const response = await DataGetApi(
        config.BASEURL + 'updateBatchInfo',
        {
          companyId: companyId,
          locationId: locationPaydetails ? locationPaydetails._id : '',
          locationMid: locationPaydetails ? locationPaydetails.mId : '',
          fromDate: fromDate,
          toDate: toDate,
        },
        token,
        gatewayDetails
      );

      if (typeof response.data !== 'undefined' && response.data.success) {
        await Promise.all([getLastUpdatedBatchDate(), getBatchRecord()]);
      } else if (response.isAxiosError && response.response?.status === 400) {
        enqueueSnackbar(`${response.response.data?.error || response.response.statusText || 'Bad Request'}`, {
          variant: 'error',
          persist: false,
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        });
      }
    } catch (err) {
      console.error('Error updateBatchInfo', err);
      setError([err.message]);
    } finally {
      setButtonLoader(false);
      setIsDisabledRefresh(false);
      setLoading(false);
    }
  };

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: false,
    download: true,
    downloadOptions: {
      filename: 'Batchdata.csv',
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
  };

  const renderBatchNumber = (value, tableMeta, history) => {
    const handleBatchClick = () => {
      const targetRow = tableMeta.tableData[tableMeta.rowIndex];
      const targetBatchLocationId = targetRow[10]; // locationId is at 10th index - see mapping in extractBatchDataSet
      const targetBatchLocationMId = targetRow[9]; // locationMId is at 9th index - see mapping in extractBatchDataSet

      history.push(`/batch/${value}/${targetBatchLocationId}/${targetBatchLocationMId}`);
    };

    return (
      <Tooltip title="View batch">
        <button className={classes.Underline} onClick={handleBatchClick} style={{ cursor: 'pointer' }}>
          {value}
        </button>
      </Tooltip>
    );
  };

  const columns = [
    {
      name: 'Batch No.',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => renderBatchNumber(value, tableMeta, history),
      },
    },
    {
      name: company.id === config.COMPANY_ID_4840 ? 'Company' : 'Location',
    }, //show Company column for 4840 company
    'TXN Count',
    'Sales Count',
    'Refund Count',
    'Sales Amt',
    'Refund Amt',
    'Net Amt',
    'Settled Date',
  ];

  const getBatchRecord = async () => {
    setLoading(true);
    setError([]);

    const user = getUser();
    setCompany(user.company);
    const companyId = user.company?._id || '';

    const param = {
      fromDate,
      toDate,
      companyId,
      locationId: locationSelectedwithmid.map((item) => item._id),
      locationMid: locationSelectedwithmid.map((item) => item.mId),
    };

    try {
      const response = await props.getBatchData(param);
      const result = response.payload.batchData;
      if (result.status === 200) {
        const batchDataSet = extractBatchDataSet(result.data.data.batches);
        setLoading(false);
        setBatchData(batchDataSet);
      }
    } finally {
      setTimeout(() => {
        setError([]);
      }, 3000);
      setLoading(false);
    }
  };

  const extractBatchDataSet = (batches) => {
    return (
      batches
        .map((item) => {
          return {
            number: item.number,
            Location: getLocationText(item),
            transaction_count: item.transaction_count,
            sales_count: item.sales_count,
            refund_count: item.refund_count,
            sales_amount: formatCurrency(item.sales_amount),
            refund_amount: formatCurrency(item.refund_amount),
            net_amount: formatCurrency(item.net_amount),
            created_at: moment(item.created.at).format('M/D/YYYY'),
            locationMid: item.locationMid,
            locationId: item.locationId._id,
          };
        })
        // this mapping is transforming array of object to array of arrays for MUI Table
        .map((dataSet1) => Object.values(dataSet1))
    );
  };

  const getLocationText = (item) => {
    //show company name for 4840 company
    return company?.id === config.COMPANY_ID_4840 ? company?.dbaName : item.locationId?.locationName;
  };

  const getLastUpdatedBatchDate = async () => {
    setLoading(true);
    let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    let param = {
      fromDate: fromDate,
      toDate: toDate,
      locationId: locationPaydetails._id,
      locationMid: locationPaydetails.mId,
    };
    localStorage.setItem('batchParams', JSON.stringify(param));

    const response = await props.getLastUpdatedBatchData(param);
    let { data } = response?.payload?.data ?? [];
    if (data.success && !isNull(data.data)) {
      setLastUpdatedDate(new Date(data.data.updatedAt).toLocaleDateString());
    }
    setLoading(false);
  };

  const getLocationData = async () => {
    try {
      const companyId = JSON.parse(localStorage.getItem('user')).company._id;
      // fetching location list for the company using redux action
      await props.locationList({ companyId });

      /**
       * Historically project is using localstorage instead of redux for state management
       * Since localstorage is not reactive to state change, user object is fetched again
       */
      let user = getUser();
      const locationsArray = getUser()?.location.reduce(reduceLocationData, []);

      if (user.permissions.allLocaton) {
        const allLocations = { locationName: 'All Locations' };
        locationsArray.unshift(allLocations);
      }
      setLocationsData(locationsArray);
    } catch (err) {
      console.error('Error fetching location data:', err);
    }
  };

  const reduceLocationData = (accumulator, location) => {
    const addLocationToMidArray = (mId, midType) => {
      const val1 = {
        _id: location._id,
        _id_mid: `${location._id}_${mId}_${midType}`,
        midType,
        locationMid: mId,
        midSelect: mId === location.mId ? 'mId' : 'mId2',
        address1: location.address1,
        address2: location.address2,
        contactName: location.contactName,
        company: location.company,
        city: location.city,
        country: location.country,
        createdAt: location.createdAt,
        email: location.email,
        id: location.id,
        isDeleted: location.isDeleted,
        locationName:
          location.locationName.length > 40 ? `${location.locationName.substr(0, 40)}...` : location.locationName,
        mId: location.mId,
        mId2: location.mId2,
        notes: location.notes,
        paytraceKey: location.paytraceKey,
        paytracePassword: location.paytracePassword,
        paytraceUserName: location.paytraceUserName,
        phone: location.phone,
        state: location.state,
        status: location.status,
        timeZone: location.timeZone,
        zip: location.zip,
        _v: location._v,
      };
      accumulator.push(val1);
    };

    if (location.mId) {
      addLocationToMidArray(location.mId, 'Traditional');
    }

    if (location.mId2) {
      addLocationToMidArray(location.mId2, 'Surcharge');
    }

    return accumulator;
  };

  const addLocation2 = (arr) => {
    setLocationSelectedwithmid(arr);
    setError([]);
  };

  return (
    <Paper className={classes.root}>
      {getUser().role !== config.SUPER_ADMIN_ROLE &&
      // Hide locations dropdown for 4840 company
      company.id !== config.COMPANY_ID_4840 ? (
        <Grid
          className="navGrid contentPart"
          container
          direction="row"
          style={{ marginBottom: `${marginBottom}px`, marginLeft: '2px' }}
          ref={containerRef}
        >
          <Grid item xs={12}>
            <FormControl fullWidth style={{ zIndex: '101' }}>
              <IntegrationDownshift
                isLocationExists={true}
                selectedLocations={locationSelectedwithmid}
                locationData={locationData}
                addLocation2={addLocation2}
              />
            </FormControl>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      <LoadingOverlay active={loading} spinner text={loadingText}>
        {error.length > 0 ? <ErrorMessage errors={[error]} /> : ''}

        <MuiThemeProvider theme={getDatePickerThemes()}>
          <Grid container>
            <RangedDatePicker
              id="ranged-date-picker"
              fromValue={fromDate}
              toValue={toDate}
              fromOnChange={(newDate) => handleFromDateChange(newDate)}
              toOnChange={(newDate) => handleToDateChange(newDate)}
              closeOnSelect
              format="MM/dd/yyyy"
              maxDate={todayDate}
            />
            <Grid className={(classes.updatedRow, 'custom-class')} style={{ paddingLeft: '50px' }}>
              <label className={classes.labelDate}>Last Updated Date: {lastUpdatedDate}</label>
              <Button
                className={classes.buttonSearch}
                variant="outlined"
                color="primary"
                disabled={isDisabledRefresh}
                onClick={handleRefreshBatchInfo}
              >
                {buttonLoader ? (
                  <>
                    <SpinnerLoader {...props} />
                    <p style={{ marginLeft: '10px' }}>Updating</p>
                  </>
                ) : (
                  'Refresh'
                )}
              </Button>
            </Grid>
          </Grid>
        </MuiThemeProvider>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={'Batch Lists'}
            data={batchData}
            columns={columns}
            options={options}
            className={'datatables-noprint'}
          />
        </MuiThemeProvider>
      </LoadingOverlay>
    </Paper>
  );
}

function mapStateToProps(state) {
  return { batchData: state.BatchReducer };
}

export default connect(mapStateToProps, { getBatchData, getLastUpdatedBatchData, locationList })(Batches);

Batches.propTypes = {
  getBatchData: PropTypes.func,
  getLastUpdatedBatchData: PropTypes.func,
  locationList: PropTypes.func,
  locationData: PropTypes.any,
};
